.navbar {
  background-color: #FFF;
  padding: 0;
}

.navbar li a {
  color: #666;
}

.navbar li.active a {
  color: #000;
}