.color-select-outer {
  padding: 8px;
  border: 1px solid #CCC;
  margin: 1px 2px;
}

.color-select-outer:hover {
  border: 1px solid #111;
}

.color-select-outer.active {
  border: 2px solid #111;
  margin: 0 1px
}

.color-select-outer:first-of-type {
  margin-left: 0px
}

.color-select-outer.active:first-of-type {
  margin-left: -1px
}

.color-select-inner {
  width: 80px;
  height: 14px;
  background-color: #900;
}