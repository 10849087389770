.tab-link {
  text-decoration: none;
  color: #999;
}

.tab-link:hover {
  text-decoration: none;
  color: #333;
}

.tab-link.active {
  color: #000;
}


.highcard {
  min-height: 800px;
}