.panel-info {
  background-color: #EEE;
  border: 1px solid #AAA;
  color: #333;
}

.panel-info>.panel-heading {
  background-color: #FFF;
  border-color: #AAA;
  border-bottom: 1px solid #AAA;
  color: #333;
  font-weight: 'bold';
}

.panel-heading {
  padding: 10px 15px;
  border: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.panel-heading>h3 {
  font-size: 16px;
}