.bg-highlight {
  background-color: #EEF;
}


table.dataTable {
  border-collapse: collapse !important;
}

.deleted > td {
  color: #999
}
