.btn{
  border-radius: 2px;
}

.btn-primary, .btn-primary:visited {
  background-color: rgb(28, 67, 173);
  border-color: #333;
  color: #FFF;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:active:focus {
  background-color: rgb(31, 141, 214);
  border-color: #333;
}

.btn-primary:disabled {
  background-color: #778191;
  border-color: #666;
}


.btn-outline-primary, .btn-outline-primary:visited {
  border-color: #1378BB;
  color: #1378BB;
}

.btn-outline-primary:active, .btn-outline-primary:hover {
  background-color: #1378BB;
  color: #FFF;
}


.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}


.bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.document-content {
  background-color: rgb(6, 173, 95);
  border-color: black;
  color: #FFF;
}

.document-content:active, .document-content:focus, .document-content:hover {
  background-color: rgb(10, 119, 77);
}

.news-content {
  background-color: rgb(160, 120, 9);
  border-color: black;
  color: #FFF;
}

.news-content:active, .news-content:focus, .news-content:hover {
  background-color: rgb(119, 117, 10);
}

.reference-content {
  background-color: #1378BB;
  border-color: black;
  color: #FFF;
}

.reference-content:active, .reference-content:focus, .reference-content:hover {
  background-color: rgb(10, 75, 119);
}

.alert-content {
  background-color: rgb(187, 19, 19);
  border-color: black;
  color: #FFF;
}

.alert-content:active, .alert-content:focus, .alert-content:hover {
  background-color: rgb(119, 10, 10);
}

.message-content {
  background-color: rgb(143, 12, 121);
  border-color: black;
  color: #FFF;
}

.message-content:active, .message-content:focus, .message-content:hover {
  background-color: rgb(136, 4, 118);
}

.event-content {
  background-color: rgb(120, 19, 187);
  border-color: black;
  color: #FFF;
}

.event-content:active, .event-content:focus, .event-content:hover {
  background-color: rgb(79, 10, 119);
}