.form-control {
  padding: 6px 8px 6px 8px;
  border-radius: 0px;
  color: #3D4850;
  border: 1px solid #AEBFCB;
}

.form-control input {
  height: 40px;
}

.jqte_editor, .jqte_source {
  min-height:300px !important;
}

.jqte {
  margin: 0 !important;
}