.tagify {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 0;
  cursor: text;
  position: relative;
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s
}

@-webkit-keyframes tags--bump {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
}

@keyframes tags--bump {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
  }
}

.tagify:hover {
  border-color: #ccc
}

.tagify.tagify--focus {
  border-color: #85c8ea;
}

.tagify[readonly] {
  cursor: default
}

.tagify[readonly]>.tagify__input {
  visibility: hidden;
  width: 0;
  margin: 5px 0
}

.tagify[readonly] x {
  display: none
}

.tagify[readonly] tag>div {
  padding: .3em .5em
}

.tagify[readonly] tag>div::before {
  background: -webkit-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: -o-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  -webkit-box-shadow: none;
  box-shadow: none
}

.tagify+input, .tagify+textarea {
  border: 0;
  display: none
}

.tagify tag {
  display: inline-block;
  margin: 5px 0 5px 5px;
  position: relative;
  z-index: 1;
  cursor: default;
  -webkit-transition: .13s ease-out;
  -o-transition: .13s ease-out;
  transition: .13s ease-out
}

.tagify tag.tagify--editable>div::before {
  -webkit-box-shadow: 0 0 0 2px #d3e2e2 inset!important;
  box-shadow: 0 0 0 2px #d3e2e2 inset!important
}

.tagify tag.tagify--editable.tagify--invalid>div::before {
  -webkit-box-shadow: 0 0 0 2px #d39494 inset!important;
  box-shadow: 0 0 0 2px #d39494 inset!important
}

.tagify tag>div {
  vertical-align: top;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  padding: .3em .5em;
  color: #000;
  border-radius: 8px;
  background: #DDD;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: .13s ease-out;
  -o-transition: .13s ease-out;
  transition: .13s ease-out;
  padding-right: 1.5em
}

.tagify tag>div>* {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top
}

.tagify tag>div>[contenteditable] {
  outline: 0
}

.tagify tag>div::before {
  content: '';
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 16px #e5e5e5 inset;
  box-shadow: 0 0 0 16px #e5e5e5 inset;
  z-index: -1;
  pointer-events: none;
  -webkit-transition: 120ms ease;
  -o-transition: 120ms ease;
  transition: 120ms ease;
  -webkit-animation: .3s tags--bump 1 ease-out;
  animation: .3s tags--bump 1 ease-out
}

.tagify tag:hover:not([readonly]) div::before {
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  -webkit-box-shadow: 0 0 0 16px #d3e2e2 inset;
  box-shadow: 0 0 0 16px #d3e2e2 inset
}

.tagify tag.tagify--noAnim {
  -webkit-animation: none;
  animation: none
}

.tagify tag.tagify--hide {
  width: 0!important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  pointer-events: none
}

.tagify tag.tagify--mark div::before {
  -webkit-animation: none;
  animation: none
}

.tagify tag.tagify--notAllowed div>span {
  opacity: .5
}

.tagify tag.tagify--notAllowed div::before {
  -webkit-box-shadow: 0 0 0 20px rgba(211, 148, 148, .44) inset!important;
  box-shadow: 0 0 0 20px rgba(211, 148, 148, .44) inset!important;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.tagify tag[readonly] x {
  display: none
}

.tagify tag[readonly]>div {
  padding: .3em .5em
}

.tagify tag[readonly]>div::before {
  background: -webkit-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: -o-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  -webkit-box-shadow: none;
  box-shadow: none
}

.tagify tag x {
  font: 14px/16px Serif;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  right: calc(.5em - 2px);
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out
}

.tagify tag x::after {
  content: "\00D7"
}

.tagify tag x:hover {
  color: #fff;
  background: #c77777
}

.tagify tag x:hover+div>span {
  opacity: .5
}

.tagify tag x:hover+div::before {
  -webkit-box-shadow: 0 0 0 20px rgba(211, 148, 148, .3) inset!important;
  box-shadow: 0 0 0 20px rgba(211, 148, 148, .3) inset!important;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.tagify__input {
  display: block;
  min-width: 110px;
  margin: 5px;
  padding: .3em .5em;
  position: relative
}

.tagify__input:empty::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: .5;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none
}

.tagify__input:focus {
  outline: 0
}

.tagify__input:focus::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: 0;
  -webkit-transform: translatex(6px);
  -ms-transform: translatex(6px);
  transform: translatex(6px)
}

@supports (-moz-appearance:none) {
  .tagify__input:focus::before {
    display: none
  }
}

.tagify__input:focus:empty::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: .3;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none
}

@supports (-moz-appearance:none) {
  .tagify__input:focus:empty::before {
    display: inline-block
  }
}

.tagify__input::before {
  content: attr(data-placeholder);
  line-height: 1.8;
  position: absolute;
  top: 0;
  z-index: 1;
  color: #000;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0
}

@supports (-moz-appearance:none) {
  .tagify__input::before {
    line-height: inherit;
    position: relative
  }
}

.tagify__input::after {
  content: attr(data-suggest);
  color: #000;
  opacity: .3;
  pointer-events: none
}

.tagify__input tag {
  margin: 0 2.5px
}

.tagify__input tag>div {
  padding-top: 0;
  padding-bottom: 0
}

.tagify__input tag>div::before {
  top: -3px;
  bottom: -3px
}

.tagify__input tag:hover:not([readonly])>div::before {
  top: -3px;
  bottom: -3px;
  left: 0;
  right: 0
}

.tagify--mix .tagify__input {
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 100%
}

.tagify__dropdown {
  position: absolute;
  z-index: 999;
  background: #fff;
  max-height: 300px;
  overflow: auto;
  border: 1px solid #85c8ea;
  -webkit-box-shadow: 0 2px 4px -2px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, .2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.tagify__dropdown__item {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  padding: .35em .6em;
  margin: 2px;
  cursor: pointer;
  border-radius: 3px;
  position: relative
}

.tagify__dropdown__item--active {
  background: #e5e5e5
}

.tagify__dropdown__item:active {
  background: #f2f2f2
}